import { useState } from "react";
import Button from "../controls/buttons";

interface IMenuButton {
  toggleMenu: React.MouseEventHandler<HTMLButtonElement>;
  showMenu: boolean;
}

type Link = {
  label: string;
  href: string;
};

const links = [
  {
    label: `About`,
    href: `#about`,
  },
  {
    label: `Hospitality`,
    href: `#Hospitality`,
  },
  {
    label: `Commercial`,
    href: `#Commercial`,
  },
  {
    label: `Residential`,
    href: `#Residential`,
  },
];

const secondaryLinks = [
  {
    label: `Contact us`,
    href: `#contact`,
  },
];

const MenuButton = ({ toggleMenu, showMenu }: IMenuButton) => (
  <button
    type="button"
    aria-controls="mobile-menu"
    aria-expanded={showMenu}
    onClick={toggleMenu}
    className="p-2 text-gray-500"
  >
    <span className="sr-only">Open menu</span>
    {showMenu ? (
      <svg
        className="h-6 w-6"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
        width={24}
        height={24}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    ) : (
      <svg
        className="h-6 w-6"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
        width={24}
        height={24}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M4 6h16M4 12h16M4 18h16"
        />
      </svg>
    )}
  </button>
);

const MobileMenu = () => (
  <div className="md:hidden">
    <div className="px-5 pt-2 pb-3 space-y-1 sm:px-3">
      {links.map((link: Link) => (
        <a
          href={link.href}
          className="text-[#004aad] text-4xl lg:text-7xl font-bold block px-5 py-5 text-base"
          key={link.label}
        >
          {link.label}
        </a>
      ))}
    </div>
    <div className="pt-4 pb-3 border-t border-gray-400">
      <div className="px-2 space-y-1">
        {secondaryLinks.map((link: Link) => (
          <a
            key={`mobile-${link.label}`}
            href={link.href}
            className="text-[#004aad] text-4xl lg:text-7xl font-bold block px-3 py-2 text-base"
          >
            {link.label}
          </a>
        ))}
      </div>
    </div>
  </div>
);

const Navigation = () => {
  const [showMenu, setShowMenu] = useState(false);
  const toggleMenu = () => setShowMenu(!showMenu);

  return (
    <nav className="bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-24">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <p className="font-poppins-light font-medium text-[#004aad] select-none text-left text-5xl pb-2 tracking-tight">
                H Projects
              </p>
              <p className="text-[#004aad] fw-700 select-none text-center font-bold">
                Collaboration Creates
              </p>
            </div>
            <div className="hidden md:block">
              <div className="ml-10 flex items-baseline space-x-4 text-xl fw-900 ">
                {links.map((link: Link) => (
                  <a
                    key={link.label}
                    href={link.href}
                    className="text-[#004aad] font-bold hover:text-gray-600 px-5 py-2 rounded-md font-large select-none"
                  >
                    {link.label}
                  </a>
                ))}
              </div>
            </div>
          </div>
          <div className="hidden md:block">
            <div className="ml-4 flex items-center md:ml-6">
              <Button
                primary
                modifier="bclassNameorder-0 mr-2 select-none"
                link="#contact"
              >
                Contact us
              </Button>
            </div>
          </div>
          <div className="-mr-2 flex md:hidden">
            <MenuButton showMenu={showMenu} toggleMenu={toggleMenu} />
          </div>
        </div>
      </div>
      {showMenu ? <MobileMenu /> : null}
    </nav>
  );
};

export default Navigation;
