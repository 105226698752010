import { useEffect, useState } from "react";
import Carousel from "../../controls/carousel";
import { IoMdCloseCircleOutline } from "react-icons/io";

export interface ICaseStudy {
  title: string;
  images: string[];
}

export interface IProject {
  pageTitle: string;
}

const Cases: React.FC<IProject> = (project: IProject) => {
  const [slides, setSlides] = useState<string[]>([]);
  const [caseStudies, setCaseStudies] = useState<ICaseStudy[]>([]);
  let url = encodeURI(`${project.pageTitle}data.json`);

  const getCaseStudies = async () => {
    const caseStudyData = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }).then(function (response) {
      return response.json();
    });
    setCaseStudies(caseStudyData as unknown as ICaseStudy[]);
  };

  useEffect(() => {
    getCaseStudies();
  }, []);

  let emptyArray: string[] = [];

  const setCaseStudy = (idx: number) => {
    setSlides(caseStudies[idx].images);
    window.location.href = `/#${project.pageTitle}CaseStudy`;
  };

  const initializeCaseStudy = () => {
    setSlides(emptyArray);
    window.location.href = `/#${project.pageTitle}`;
  };

  return (
    <section id={project.pageTitle}>
      <div className="w-full bg-white relative">
        <div className="absolute left-0 top-0 w-full overflow-hidden"></div>
        <div className="max-w-7xl mx-4 lg:mx-auto pt-10 lg:pt-10">
          <h1 className="text-[#004aad] text-4xl lg:text-7xl font-bold text-center">
            {project.pageTitle}
          </h1>
          <div className="mx-auto pt-24">
            <div className="w-full flex flex-wrap justify-around">
              {caseStudies.map((article, idx) => (
                <div
                  key={article.title}
                  className="
                    xl:w-1/3 sm:w-5/12 sm:max-w-xs relative mb-32 lg:mb-20
                          xl:max-w-sm lg:w-1/2 w-11/12 sm:mx-0 cursor-pointer hover:scale-105`
                  "
                >
                  <button
                    onClick={() => {
                      initializeCaseStudy();
                      setCaseStudy(idx);
                    }}
                    className="w-full h-content"
                  >
                    <div className="h-64 z-20">
                      <img
                        src={article.images[0]}
                        alt={"Gallery Item " + idx}
                        className="h-full w-full object-cover overflow-hidden rounded"
                        width={400}
                        height={300}
                      />
                    </div>
                    <div className="p-4 shadow-lg w-full mx-auto -mt-8 bg-h-blue rounded-b z-30 relative text-xl">
                      <p className="font-poppins-light font-medium text-white text-center pb-1 text-sm">
                        {article.title}
                      </p>
                    </div>
                  </button>
                </div>
              ))}
            </div>
          </div>
          <div
            id={project.pageTitle + "CaseStudy"}
            className="m-auto py-16 px-14 sm:px-6 lg:px-8 "
          >
            {slides.length !== 0 ? <Carousel slides={slides}></Carousel> : null}
            {slides.length !== 0 ? (
              <button
                onClick={() => initializeCaseStudy()}
                className="text-[#004aad]"
              >
                <IoMdCloseCircleOutline size={40} />
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cases;
