import { useState, useRef, useEffect } from "react";
import {
  BsFillArrowRightCircleFill,
  BsFillArrowLeftCircleFill,
  BsFullscreen,
} from "react-icons/bs";
import { useSwipeable } from "react-swipeable"; // Add this import for swipe gesture support

interface ICarouselProps {
  slides: string[];
}

const Carousel: React.FC<ICarouselProps> = ({ slides }) => {
  const [current, setCurrent] = useState(0);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [imageAspectRatio, setImageAspectRatio] = useState(1); // Track aspect ratio of the image
  const [carouselHeight, setCarouselHeight] = useState<string>("60vh"); // Initial carousel height
  const [carouselWidth, setCarouselWidth] = useState<string>("100%"); // Initial carousel width
  const imageRef = useRef<HTMLImageElement | null>(null); // Reference to the image

  // Update aspect ratio and adjust carousel size when image is loaded
  useEffect(() => {
    if (imageRef.current) {
      const aspectRatio =
        imageRef.current.naturalWidth / imageRef.current.naturalHeight;
      setImageAspectRatio(aspectRatio);
      adjustCarouselSize(aspectRatio);
    }
  }, [current]);

  // Adjust carousel size based on image aspect ratio
  const adjustCarouselSize = (aspectRatio: number) => {
    if (aspectRatio > 1) {
      // Landscape orientation
      setCarouselHeight("60vh");
      setCarouselWidth("100%");
    } else {
      // Portrait orientation
      setCarouselHeight("auto");
      setCarouselWidth("100%"); // Reduce the width to make it fit better on the screen
    }
  };

  // Navigate to the previous slide
  const previousSlide = () => {
    setCurrent((current - 1 + slides.length) % slides.length);
  };

  // Navigate to the next slide
  const nextSlide = () => {
    setCurrent((current + 1) % slides.length);
  };

  // Toggle full-screen mode
  const toggleFullScreen = () => {
    const carouselElement = document.getElementById("carousel") as HTMLElement;
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    if (carouselElement) {
      if (!document.fullscreenElement && !document.webkitFullscreenElement) {
        if (carouselElement.requestFullscreen) {
          carouselElement.requestFullscreen();
        } else if ((carouselElement as any).webkitRequestFullscreen) {
          (carouselElement as any).webkitRequestFullscreen();
        }
        setIsFullScreen(true);
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if ((document as any).webkitExitFullscreen) {
          (document as any).webkitExitFullscreen();
        }
        setIsFullScreen(false);
      }

      // Safari-specific fix: Ensure width expands properly
      if (isSafari) {
        setTimeout(() => {
          setCarouselWidth("100vw");
          setCarouselHeight("100vh");
        }, 100); // Small delay for smooth transition
      }
    }
  };

  // Swipeable gesture configuration
  const swipeHandlers = useSwipeable({
    onSwipedLeft: nextSlide,
    onSwipedRight: previousSlide,
    trackMouse: true, // Keeps track of mouse events for swipe-like behavior on desktop
  });

  // If no slides are provided
  if (!slides || slides.length === 0) {
    return (
      <div className="h-[60vh] flex items-center justify-center">
        No slides available
      </div>
    );
  }

  return (
    <div
      id="carousel"
      className={`relative overflow-hidden`}
      style={{
        width: carouselWidth,
        height: isFullScreen ? "100vh" : carouselHeight, // Full-screen height adjustment
      }}
      {...swipeHandlers} // Apply swipe gestures to the carousel container
    >
      {/* Image Container */}
      <div
        className="flex transition-transform duration-500 ease-in-out"
        style={{ transform: `translateX(-${current * 100}%)` }}
      >
        {slides.map((src, idx) => (
          <div
            key={idx}
            className="flex-shrink-0 w-full h-full flex justify-center items-center bg-black"
          >
            <img
              ref={imageRef}
              src={src}
              alt={`Slide ${idx + 1}`}
              className={`${
                isFullScreen ? "object-contain" : "object-cover"
              } ease-in-out duration-500 flex flex justify-center items-center`}
              style={{
                height: isFullScreen ? "100vh" : "60vh",
                width: isFullScreen ? "-webkit-fill-available" : "100%",
                maxHeight: isFullScreen ? "-webkit-fill-available" : "100%",
                maxWidth: isFullScreen ? "100%" : "100%",
              }}
            />
          </div>
        ))}
      </div>

      {/* Controls */}
      {slides.length > 1 ? (
        <div className="absolute top-0 w-full h-full flex justify-between items-center px-5">
          <button
            onClick={previousSlide}
            aria-label="Previous Slide"
            className="text-white bg-black bg-opacity-50 rounded-full p-2 hover:bg-opacity-75"
          >
            <BsFillArrowLeftCircleFill size={30} />
          </button>
          <button
            onClick={nextSlide}
            aria-label="Next Slide"
            className="text-white bg-black bg-opacity-50 rounded-full p-2 hover:bg-opacity-75"
          >
            <BsFillArrowRightCircleFill size={30} />
          </button>
        </div>
      ) : null}

      {/* Full-Screen Button 
      <button
        onClick={toggleFullScreen}
        aria-label="Toggle Full Screen"
        className="absolute top-5 right-5 text-white bg-black bg-opacity-50 p-2 rounded-full hover:bg-opacity-75"
      >
        <BsFullscreen size={24} />
      </button>*/}

      {/* Dot Indicators */}
      <div className="absolute bottom-5 flex justify-center w-full gap-2">
        {slides.map((_, i) => (
          <div
            key={i}
            onClick={() => setCurrent(i)}
            aria-label={`Go to slide ${i + 1}`}
            className={`w-4 h-4 rounded-full cursor-pointer ${
              i === current ? "bg-white" : "bg-gray-400"
            }`}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
