/* const Header = () => {
  return (
    <div
      id="/"
      className="bg-hero-pattern bg-cover bg-no-repeat h-screen mx-auto py-16 px-14 sm:px-6 lg:px-8"
    >
      <div className="flex flex-col justify-center items-center">
        {/*<h1 className="text-[#004aad] text-4xl lg:text-7xl font-bold p-4 rounded-lg font-bold text-4xl md:text-5xl lg:text-8xl text-center">
          H Projects
        </h1>
        <h1 className="text-[#004aad] text-4xl lg:text-7xl font-bold p-4 rounded-lg text-4xl md:text-5xl lg:text-8xl text-center leading-snug">
          Collaboration Creates...
        </h1>}
      </div>
    </div>
  );
};

export default Header;
 */

import React from "react";

const Header: React.FC = () => {
  return (
    <div className="relative w-full h-screen">
      {/* Video background */}
      <video
        className="absolute top-0 left-0 w-full h-full object-cover"
        autoPlay
        muted
        loop
        playsInline
        preload="auto"
      >
        <source
          src="https://hprojectswebcontainerci.blob.core.windows.net/images/Website%20Images/F%20&%20B/Hikina/mini%20tour.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default Header;
